.gallery-container{
   display: grid;
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 4rem;
    margin-top: 2rem;
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}
.photo-item{
   flex: 0 0 cal(33.333% - 1rem);
   text-align: center;
}
.photo-item img{
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;

}
.photo-description{
   margin-bottom: 2rem;
}

@media screen and (max-width: 640px) {
    .photo-item{
        flex: 0 0 cal(50% - 1rem);
    }
    .gallery-container{
        justify-content: center;
    }

    @media screen and (max-width: 480px) {
        .photo-item{
            flex: 0 0 100%;
        }
        
    }

    
}

/* Add this CSS for the modal */
.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: center;
    align-items: center;
    
    z-index: 10000;
  }
  
  .modal-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 8px;
  }
  
  .close-button{
    position:relative;
    display: flex;
    flex-direction: row;
    font-size: 45px;
    color: #fff;
    padding: 14px;
    border-radius: 45%;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    
  }
  
  .close-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    
  }
  