.footer{
    width: 100%;
    padding: 6rem 0;
    background-color: rgba(19, 19, 29, 0.8)
}
.footer__container{
   max-width: 1140px;
    margin:  auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.footer__container__left{
    display: flex;
    max-width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
}
.location{
    display: flex;
    margin-bottom: 0.8rem;

}
.location p{
    line-height: 30px;

}
.footer__container__right{
    display: flex;
    max-width: 100%;
    flex-direction: column;
    height: 100%;
    padding: 1rem;
}
.social {
    margin-top: 1rem;
}
h4{
    font-size: 1.2rem;
    padding-bottom: 0.7rem;
}
p{
    font-size: 1rem;
    padding-bottom: 0.7rem
}

@media screen and (max-width: 640px) {
    .footer__container{
        grid-template-columns: repeat(1, 1fr);
    }

}
    
