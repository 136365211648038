.project-heading {
    text-align: center;
    padding: 4rem 0 2rem 0;
    color: #fff; /* Ensure project heading is visible */
}

.year-heading {
    color: #fff; /* Make the year heading white */
    font-size: 2rem; /* Adjust font size as needed */
    text-align: center;
    padding: 2rem 0; /* Add space above and below the year */
}

.project-container {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust for fewer than 3 items */
    grid-gap: 40px;
    padding-bottom: 5rem;
}

.project-card {
    background: #1a1919;
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure card takes full height available */
}

.project-card img {
    width: 100%;
    height: 200px; /* Set a fixed height for all images */
    object-fit: cover; /* Ensure image covers the assigned area */
    flex-shrink: 0; /* Ensure image does not shrink */
}

.project-title {
    color: #fff;
    padding: 1rem 0;
    flex-shrink: 0; /* Ensure title does not shrink */
    text-align: center; /* Center align the title */
}

.pro-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between; /* Distribute space between elements */
}

.pro-details p {
    padding-bottom: 1rem;
    font-size: 1.1rem;
    text-align: justify;
    flex-grow: 1; /* Allow paragraph to take available space */
}

.pro-btn {
    margin-top: auto; /* Ensure buttons are aligned at the bottom */
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.btns {
    background-color: #28a745;
}

.pro-btn .btn {
    padding: 0.5rem 1rem;
}

.work-container {
    background: #000007;
}

@media screen and (max-width: 740px) {
    .project-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }

    .project-heading {
        padding: 2rem 0 1rem 0;
    }

    .pro-btn {
        padding-bottom: 2rem;
    }
}
