.heroImage {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 60vh;
  position: relative;
}
.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heroImage ::before {
  content: "";
  background: url("https://images.unsplash.com/photo-1484417894907-623942c8ee29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1032&q=80");
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.heroImage h1{
    font-size: 3rem;
    text-align: center;
}
.heroImage p{
    font-size: 2rem;
    text-align: center;
}
@media screen and (max-width: 640px) {
  .heroImage h1{
    font-size: 2rem;
  }
  .heroImage p{
    font-size: 1.5rem;
  }
}
    
