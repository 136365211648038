body.active-modal {
    overflow: hidden;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modal-content {
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 90%;
    width: 600px;
    color: black;
    position: relative;
    box-sizing: border-box;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.close-modal {
    align-self: flex-end;
    padding: 5px 7px;
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 3px;
}

.modal-content h2 {
    margin-top: 1rem;
    color: black;
    line-height: 25px;
    width: 100%;
    text-align: center;
}

.modal-content p {
    color: black;
    line-height: 25px;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    width: 100%;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

@media screen and (max-width: 740px) {
    .modal-content {
        padding: 10px;
    }

    .close-modal {
        position: relative;
        top: 0;
        right: 0;
    }

    .modal-content h2 {
        margin-top: 1rem;
        font-size: 1.2rem;
    }
}
