@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

body{
  background: #1b1b1b;
}
h1, h4, p,
a{
  color: #fff;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn{
  background-color: rgb(144, 238, 144);
  font-size: 1rem;
  padding: 12px 30px;
  border-radius: 4px;
  border: 1px solid rgb(233, 241, 233);
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
}
.btn-light{
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.btn:hover{
  background-color: rgba(255, 255, 255,0);
  color: #d3d3d3;
  transition: 0.35;
}