label{
    color: white;
    margin-bottom: 0.5rem;

}
form{
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 600px;
}
form input, form textarea{
    padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
    border-radius: 5px;
    outline: none;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
    color: #f4f4f4;
}